import React from "react";
import { Loader } from "../icons";

interface LoaderComponentProps {
  size?: number;
  text?: string;
  color?: string;
  isFullPage?: boolean;
}

const LoaderComponent: React.FC<LoaderComponentProps> = ({
  size = 150,
  text = "Loading",
  color = "#000",
  isFullPage = false,
}) => {
  return (
    <div
      className={`flex justify-center items-center ${
        isFullPage ? "h-screen fixed top-0 left-0 w-full bg-black/50" : "h-11"
      }`}
    >
      <div>
        <Loader size={size} color={color} />
      </div>
    </div>
  );
};

export default LoaderComponent;
