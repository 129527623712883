// React
import React from "react";

// Next
import Link from "next/link";
import { useLocale, useTranslations } from "next-intl";
import Image from "next/image";

// Components
import Button from "../Button";

// Utils
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";

type Props = {
  fqcn_bui: IFQCN_BUI;
  ticketBtn?: {
    onClose: () => void;
    status: string;
    isLoading?: boolean;
  };
};

const Navbar: React.FC<Props> = ({ fqcn_bui, ticketBtn }) => {
  const locale = useLocale();
  const t = useTranslations("Navbar");

  return (
    <article className="w-full py-3 flex justify-center items-center">
      <div className="max-w-screen w-full flex items-center justify-between flex-col md:flex-row">
        <div className="w-full flex items-center justify-center md:justify-between pl-1 pr-4">
          <Link id={getFQCN(fqcn_bui, "link-logo")} href={`/${locale}`}>
            <div className="bg-white border border-gray-300 rounded-xl pt-2 scale-80 shadow m-3 pb-2 w-32 h-12">
              <div className="flex place-content-center">
                <Image
                  id={getFQCN(fqcn_bui, "light-logo")}
                  src="/assets/images/logo-black-trans.png"
                  width={80}
                  height={80}
                  alt="phpcreation logo"
                  className="block"
                />
              </div>
            </div>
          </Link>
        </div>

        {ticketBtn && (
          <div className="flex items-center gap-4 w-full justify-center md:justify-end flex-col md:flex-row">
            {ticketBtn?.status === "New" && (
              <div className="max-w-60 w-full">
                <Button
                  className="bg-red-700 hover:bg-red-800 hover:shadow-lg hover:-translate-y-[2px] transition-all duration-300 disabled:hover:bg-red-800"
                  onClick={ticketBtn.onClose}
                  disabled={
                    ticketBtn.isLoading
                    //  || ticketBtn.status === "Closed"
                  }
                  isLoading={ticketBtn.isLoading}
                >
                  {t(
                    // ticketBtn.status === "Closed"
                    //   ? "Ticket Closed"
                    //   :
                    "Close Ticket"
                  )}
                </Button>
              </div>
            )}

            <Link href={`/${locale}/feedback`} className="max-w-60 w-full">
              <Button className="hover:shadow-lg hover:-translate-y-[2px] transition-all duration-300">
                {t("New Feedback")}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </article>
  );
};

export default Navbar;
