import React, { ButtonHTMLAttributes } from "react";
import LoaderComponent from "../LoaderComponent";

export type ButtonSize = "md" | "sm" | "xs";
export type ButtonVariant =
  | "black"
  | "black-light"
  | "white"
  | "white-light"
  | "transparent"
  | "transparent-white"
  | "red";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Control the height and width on button
   * @default md
   */
  size?: ButtonSize;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  isIcon?: boolean;
  adjustTextWidth?: boolean;
  extendBorder?: boolean;
  /**
   * Control the background color and text color stlying of the button
   * @default black
   */
  variant?: ButtonVariant;
  isLoading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  size = "md",
  iconStart,
  iconEnd,
  isIcon = false,
  adjustTextWidth = false,
  extendBorder = false,
  variant = "black",
  type = "submit",
  isLoading = false,
  className,
  disabled = false,
  ...rest
}) => {
  return (
    <div
      className={`relative w-full ${extendBorder ? "hover:opacity-100" : ""}`}
    >
      <button
        className={`flex items-center w-full justify-center text-md font-medium bg-blue-phpr hover:bg-blue-600 text-white px-6 rounded-lg h-12 transition-all duration-200 ${
          className || ""
        } ${isIcon ? "w-17" : ""} ${
          adjustTextWidth ? "px-1" : ""
        } disabled:opacity-70 disabled:hover:bg-blue-phpr disabled:cursor-not-allowed`}
        {...rest}
        type={type}
        disabled={isLoading || disabled}
      >
        {iconStart && <i className="icon icon-start mr-2">{iconStart}</i>}
        <span className="text">{children}</span>
        {iconEnd && !isLoading && (
          <i className="icon icon-end ml-2">{iconEnd}</i>
        )}
        {isLoading && (
          <i
            className={`icon-loading ${
              isIcon
                ? "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                : ""
            }`}
          >
            <LoaderComponent size={25} color={"#ffffff"} />
          </i>
        )}
      </button>
    </div>
  );
};

export default Button;
