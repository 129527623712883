// React
import { ChangeEvent, useEffect, useState } from "react";

// Next Intl
import { useLocale, useTranslations } from "next-intl";

// Utils
import { IFQCN_BUI } from "@/utils/types/common";
import { useRouter } from "next/navigation";

type Props = {
  fqcn_bui: IFQCN_BUI;
  footerStyles?: string;
};

const Footer: React.FC<Props> = ({ fqcn_bui, footerStyles = "" }) => {
  const locale = useLocale();

  const t = useTranslations("Footer");
  const router = useRouter();

  const handleLanguageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedLocale = e.target.value;
    const currentPathname = window.location.pathname;
    const newPathname = currentPathname.replace(locale, selectedLocale);

    router.push(`${newPathname}${window.location.search}`);
  };

  return (
    <footer
      className={`flex items-center justify-center flex-col static z-10 ${footerStyles} -bottom-0 p-3 w-full text-gray-500 gap-2 border-t-[1px] border-gray-300 `}
    >
      <article className="pt-3">
        <select
          className="rounded-md px-2 py-1 border-[0.5px] border-gray-500 "
          onChange={handleLanguageChange}
          value={locale}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </article>

      <article className="flex justify-center items-center ">
        {t("Powered by")}
        <span className="text-gray-600 font-bold pr-1 pl-1">
          <a href="https://phpcreation.com/">PHPCreation Inc.</a>
        </span>
      </article>
    </footer>
  );
};

export default Footer;
